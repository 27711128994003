<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.accordion-2 variant="info">Pix</b-button>
    </b-card-header>
    <b-collapse
      id="accordion-2"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-row class="justify-content-center">
          <b-col md="10" class="bank-card">
            <div>
              <img
                src="@/assets/pix.png"
                class="thumbnail"
                width="50px"
                alt=""
              />
              - Pix
            </div>
            <div class="d-flex flex-column mt-4">
              <small>Beneficiário:</small>
              <p>
                {{ clientData.unityBankAccount[0].description }}
              </p>
            </div>
            <div class="d-flex flex-column mt-4">
              <small>Chave Pix:</small>
              <p>
                {{ clientData.unityBankAccount[0].pix_key }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

export default {
  computed:{
    clientData() {
      return this.$store.state.checkout.clientData;
    },
    unityData(){
      return this.$store.state.checkout.unityData
    },
  }
};
</script>

<style>
</style>